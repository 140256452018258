import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from "@ngrx/signals";
import { initialState } from "./template.model";
import { computed, inject } from "@angular/core";
import { TokenStore } from "src/app/auth/token.store";

import { lastValueFrom } from "rxjs";
import { NotificacoesService } from "src/app/admin-v2/data-access/notificacoes.service";

export const TemplateStore = signalStore(
  withState(initialState),

  withMethods((store) => {
    const tokenStore = inject(TokenStore, { skipSelf: true });

    return {
      setCollapsed(isCollapsed: boolean) {
        patchState(store, { isCollapsed });
      },
      setCurrentRoute(currentRoute: string) {
        patchState(store, { currentRoute });
      },
      setFirstLoad(firstLoad: boolean) {
        patchState(store, { firstLoad });
      },
      setModuloAtivo(moduloAtivo: string) {
        patchState(store, { moduloAtivo });
      },
      updateHasUsuarioOrgao() {
        patchState(store, {
          hasUsuarioOrgao: tokenStore.possuiUsuarioOrgao(),
        });
      },
    };
  }),
);
