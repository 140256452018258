import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { inject, Injectable, signal } from "@angular/core";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError, switchMap, filter, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenStore } from "../auth/token.store";
import { NzMessageService } from "ng-zorro-antd/message";
import { LogoutService } from "../auth/logout.service";
import { RefreshTokenService } from "../auth/refresh-token.service";
import { TokenExpirationService } from "../auth/token-expiration.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  // Injeção de dependências necessárias para o serviço
  private readonly tokenStore = inject(TokenStore);
  private readonly refreshTokenService = inject(RefreshTokenService);
  private readonly messageService = inject(NzMessageService);
  private readonly logoutService = inject(LogoutService);
  private readonly tokenExpirationService = inject(TokenExpirationService);

  // Flag para controlar se o token está sendo atualizado
  private isRefreshing = signal(false);
  // Subject para gerenciar o estado de atualização do token
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  // Método principal do interceptor
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Verifica se a interceptação deve ser ignorada para esta requisição
    if (this.shouldSkipInterception(request)) {
      return next.handle(request);
    }

    // Adiciona cabeçalhos de autenticação e trata erros 401
    return this.tokenExpirationService.checkTokenExpiration().pipe(
      switchMap(() => next.handle(this.addAuthHeaders(request))),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            // Para outros erros, apenas repassamos
            return throwError(() => error);
          }
        }
        return throwError(() => error);
      }),
    );
  }

  // Determina se a interceptação deve ser ignorada para certas requisições
  private shouldSkipInterception(request: HttpRequest<unknown>): boolean {
    const shouldSkip =
      !this.tokenStore.token() ||
      request.url.startsWith(`${environment.apiUrl}oauth`) ||
      request.url.startsWith(`${environment.apiUrl}public`);

    // console.log(`Skipping interception: ${shouldSkip}`, request.url);
    return shouldSkip;
  }

  // Adiciona cabeçalhos de autenticação à requisição
  private addAuthHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const headers = this.buildHeaders(request);
    return request.clone({ setHeaders: headers });
  }

  // Constrói os cabeçalhos para a requisição
  private buildHeaders(request: HttpRequest<unknown>): Record<string, string> {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${this.tokenStore.token()}`,
      Accept: request.headers.get("Accept") || "application/json",
    };

    if (!this.isSpecialEndpoint(request)) {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  }

  // Verifica se a requisição é para um endpoint especial
  private isSpecialEndpoint(request: HttpRequest<unknown>): boolean {
    const specialEndpoints = ["imagem", "vetor", "salvarVetorFaces"];
    return specialEndpoints.some((endpoint) => request.url.endsWith(endpoint));
  }

  // Lida com erros 401 (Unauthorized)
  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing()) {
      this.isRefreshing.set(true);
      this.refreshTokenSubject.next(null);

      return this.refreshTokenService.refreshToken().pipe(
        switchMap((newToken) => {
          this.isRefreshing.set(false);
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addAuthHeaders(request));
        }),
        catchError((error) => {
          this.isRefreshing.set(false);
          if (error.status === 401) {
            this.handleAuthenticationFailure();
          }
          return throwError(() => error);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthHeaders(request));
        }),
      );
    }
  }

  // Trata falha de autenticação
  private handleAuthenticationFailure(): void {
    // console.log("handleAuthenticationFailure");
    // Realiza logout após um breve atraso
    setTimeout(() => this.logoutService.logoutAbAndLocal(), 1000);

    // Exibe mensagem de erro para o usuário
    this.messageService.error(
      "Sua sessão expirou, por favor, faça login novamente.",
    );
  }
}
