import { Component, computed, inject, OnInit } from "@angular/core";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzButtonModule } from "ng-zorro-antd/button";
import { AuthService } from "src/app/auth/auth.service";
import { Router } from "@angular/router";
import { TokenStore } from "src/app/auth/token.store";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  standalone: true,
  imports: [NzButtonModule, NzWaveModule],
})
export class LoginComponent implements OnInit {
  private readonly oauthService = inject(OAuthService, { skipSelf: true });
  private readonly authService = inject(AuthService, { skipSelf: true });
  private readonly tokenStore = inject(TokenStore, { skipSelf: true });
  private readonly router = inject(Router);

  loading = computed(() => this.authService.loading());

  ngOnInit() {
    if (this.tokenStore.isValidToken()) {
      this.router.navigate(["/consultas"]);
    }
  }

  loginGovbr(): void {
    this.authService.authenticate(true);
  }
}
