/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NotificacoesStore } from "../../data-access/notificacoes.store";
import { CommonModule } from "@angular/common";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { FormsModule } from "@angular/forms";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzPaginationModule } from "ng-zorro-antd/pagination";

@Component({
  selector: "app-admin-notificacoes-sino",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,

    NzButtonModule,
    NzDividerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzIconModule,
    NzMenuModule,
    NzPaginationModule,
    NzSelectModule,
    NzSpinModule,
  ],
  templateUrl: "./admin-notificacoes-sino.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminNotificacoesSinoComponent {
  store = inject(NotificacoesStore, { skipSelf: true });
}
