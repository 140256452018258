<div class="relative flex">
    <button
        nz-button
        nz-dropdown
        [nzDropdownMenu]="notificacoes"
        nzTrigger="click"
        nzType="text"
        class="mt-0.5 p-0 text-white hover:text-white/90 focus:text-white/90 focus:ring-1 focus:ring-white/90"
    >
        <span nz-icon nzType="bell" nzTheme="outline" class="text-xl"></span>
    </button>
    @if (store.hasUnreadNotifications()) {
        <div
            class="absolute bottom-1.5 right-1.5 size-1.5 animate-pulse rounded-full bg-red-500 ring-1 ring-red-500/30"
        ></div>
    }
</div>

<nz-dropdown-menu #notificacoes="nzDropdownMenu">
    <div class="flex w-[300px] flex-col bg-white shadow-lg">
        <nz-spin [nzSpinning]="store.loading()">
            <div class="flex items-center justify-between gap-2 px-4 py-2">
                <div class="flex items-center">
                    <span class="font-medium">Notificações</span>
                    <nz-select
                        nzSize="small"
                        nzBorderless
                        [ngModel]="store.notificacoesViewType()"
                        (ngModelChange)="store.setNotificacoesViewType($event)"
                        class="ml-2 w-[88px]"
                    >
                        <nz-option nzValue="todas" nzLabel="Todas"></nz-option>
                        <nz-option nzValue="lidas" nzLabel="Lidas"></nz-option>
                        <nz-option
                            nzValue="naoLidas"
                            nzLabel="Não lidas"
                        ></nz-option>
                    </nz-select>
                </div>
                <button
                    nz-button
                    nz-dropdown
                    [nzDropdownMenu]="notificacoesMore"
                    nzTrigger="click"
                    nzType="text"
                    nzSize="small"
                    class="-mr-2 px-0"
                >
                    <span nz-icon nzType="more" nzTheme="outline"></span>
                </button>
            </div>
            <nz-divider class="my-0"></nz-divider>
            <div>
                @for (
                    notificacao of store.filteredNotificacoes();
                    track $index
                ) {
                    <nz-divider class="my-0 first:hidden"></nz-divider>
                    <div class="flex flex-col overflow-hidden px-4 py-2">
                        <div class="flex justify-between">
                            <div
                                class="flex items-center gap-1.5"
                                [class.opacity-50]="notificacao.dataLeitura"
                            >
                                @let titulo = notificacao.titulo;
                                <span
                                    class="text-xs font-medium text-neutral-900"
                                    >{{
                                        titulo.length > 15
                                            ? titulo.slice(0, 15) + "..."
                                            : titulo
                                    }}</span
                                >
                                <span
                                    class="text-2xs font-medium text-neutral-500"
                                    >{{
                                        notificacao.dataEntrega
                                            | date: "dd/MM/yy HH:mm"
                                    }}</span
                                >
                            </div>

                            <div class="flex items-center">
                                <button
                                    nz-button
                                    nzType="text"
                                    nzSize="small"
                                    (click)="
                                        store.toggleNotificacaoLida(
                                            notificacao.id
                                        )
                                    "
                                >
                                    @if (notificacao.dataLeitura) {
                                        <span
                                            nz-icon
                                            nzType="eye-invisible"
                                            nzTheme="outline"
                                        ></span>
                                    } @else {
                                        <span
                                            nz-icon
                                            nzType="eye"
                                            nzTheme="outline"
                                        ></span>
                                    }
                                </button>
                                @if (notificacao.dataLeitura) {
                                    <button
                                        nz-button
                                        nzType="text"
                                        nzSize="small"
                                        (click)="
                                            store.apagarNotificacao(
                                                notificacao.id
                                            )
                                        "
                                    >
                                        <span
                                            nz-icon
                                            nzType="delete"
                                            nzTheme="outline"
                                        ></span>
                                    </button>
                                }
                            </div>
                        </div>
                        <span
                            class="text-xs text-neutral-700"
                            [class.opacity-50]="notificacao.dataLeitura"
                            >{{ notificacao.mensagem }}</span
                        >
                    </div>
                } @empty {
                    <nz-empty
                        nzNotFoundImage="simple"
                        nzNotFoundContent="Sem notificações"
                    ></nz-empty>
                }
            </div>
            @let total = store.notificacoes().length;
            @if (total > 0) {
                <nz-divider class="my-0"></nz-divider>
                <div class="flex justify-center py-2">
                    <nz-pagination
                        [nzPageIndex]="store.pageIndex()"
                        [nzPageSize]="store.pageSize()"
                        [nzTotal]="total"
                        (nzPageIndexChange)="store.onPageIndexChange($event)"
                        nzSize="small"
                        nzSimple
                    ></nz-pagination>
                </div>
            }
        </nz-spin>
    </div>
</nz-dropdown-menu>

<nz-dropdown-menu #notificacoesMore="nzDropdownMenu">
    <ul nz-menu nzSelectable="false">
        <li nz-menu-item (click)="store.updateAllNotificacoesLidasState(true)">
            <span>Marcar todas como lidas</span>
        </li>
        <li nz-menu-item (click)="store.updateAllNotificacoesLidasState(false)">
            <span>Marcar todas como não lidas</span>
        </li>
    </ul>
</nz-dropdown-menu>
