import { PERMISSOES_POR_ROTA } from "src/app/auth/auth.constants";
import { MenuGroup } from "../data-access/template.model";

const getPermissoes = (rota: string) => PERMISSOES_POR_ROTA[rota] || [];

export function criarMenu(
  hasSomeRole: (permissoes: string[]) => boolean,
): MenuGroup[] {
  const menu: MenuGroup[] = [
    {
      displayName: "OPÇÕES",
      itens: [
        {
          displayName: "Consultas",
          disabled: true,
          iconName: "search",
          iconType: "nz-icon",
          route: "/consultas",
          visible: hasSomeRole(getPermissoes("/consultas")),
          badge: "new!",
        },
        {
          displayName: "Gerenciar suspeitas",
          disabled: true,
          iconName: "flag",
          iconType: "nz-icon",
          route: "/alertas",
          visible: hasSomeRole(getPermissoes("/alertas")),
        },
        {
          displayName: "Veículos monitorados",
          disabled: true,
          iconName: "flag",
          iconType: "nz-icon",
          route: "/veiculos-monitorados",
          visible:
            hasSomeRole(getPermissoes("/alertas")) &&
            hasSomeRole(["beta.tester"]),
        },
        {
          displayName: "Monitor de alertas",
          disabled: true,
          iconName: "desktop",
          iconType: "nz-icon",
          route: "/monitor",
          visible:
            hasSomeRole(getPermissoes("/monitor")) &&
            !hasSomeRole(["beta.tester"]),
          badge: undefined,
          tooltip: undefined,
        },

        {
          displayName: "Monitor de alertas",
          disabled: true,
          iconName: "desktop",
          iconType: "nz-icon",
          route: "/monitor-v2",
          visible:
            (hasSomeRole(getPermissoes("/monitor")) &&
              hasSomeRole(["beta.tester"]) &&
              hasSomeRole(["pegatudo:submit_query_monitor"])) ||
            (hasSomeRole(["monitor.crime.prf.c3r"]) &&
              hasSomeRole(["pegatudo:submit_query_monitor"])),
          badge: "new!",
        },
        {
          displayName: "Histórico de passagens",
          disabled: true,
          iconName: "backward",
          iconType: "nz-icon",
          route: "/passagens-historico",
          visible: hasSomeRole(getPermissoes("/passagens-historico")),
          badge: "new!",
        },
        {
          displayName: "Veículos de apoio",
          disabled: true,
          iconName: "pic-left",
          iconType: "nz-icon",
          route: "/passagens/veiculos-apoio",
          visible:
            hasSomeRole(getPermissoes("/passagens/veiculos-apoio")) &&
            !hasSomeRole(["beta.tester"]),
          badge: undefined,
          tooltip: undefined,
        },
        {
          displayName: "Veículos de apoio",
          disabled: true,
          iconName: "pic-left",
          iconType: "nz-icon",
          route: "/passagens-veiculos-apoio",
          visible:
            hasSomeRole(getPermissoes("/passagens/veiculos-apoio")) &&
            hasSomeRole(["beta.tester"]),
          badge: "new!",
        },

        {
          displayName: "Origem/Destino",
          disabled: true,
          iconName: "node-index",
          iconType: "nz-icon",
          route: "/passagens-origem-destino",
          visible: hasSomeRole(getPermissoes("/passagens-origem-destino")),
          badge: "new!",
        },
        {
          displayName: "Passagens com imagens",
          disabled: true,
          iconName: "picture",
          iconType: "nz-icon",
          route: "/passagens-imagens",
          visible: hasSomeRole(getPermissoes("/passagens-imagens")),
          badge: "new!",
        },
        {
          displayName: "Consulta Facial",
          disabled: true,
          iconName: "meh",
          iconType: "nz-icon",
          route: "/consulta-facial",
          visible:
            hasSomeRole(getPermissoes("/consulta-facial")) &&
            hasSomeRole(["beta.tester"]),
          badge: "new!",
        },
      ],
    },
  ];

  if (
    hasSomeRole(getPermissoes("/admin")) ||
    hasSomeRole(getPermissoes("/usuarios"))
  ) {
    menu.push({
      displayName: "GERENCIAMENTO",
      itens: [
        {
          displayName: "Administração",
          disabled: true,
          iconName: "bank",
          iconType: "nz-icon",
          route: "/admin",
          visible: hasSomeRole(getPermissoes("/admin")),
        },
        {
          displayName: "Administração",
          disabled: true,
          iconName: "bank",
          iconType: "nz-icon",
          route: "/admin-v2",
          visible: hasSomeRole(getPermissoes("/admin")),
          badge: "new!",
        },
        {
          displayName: "Usuários",
          disabled: true,
          iconName: "team",
          iconType: "nz-icon",
          route: "/usuarios",
          visible: hasSomeRole(getPermissoes("/usuarios")),
          badge: "new!",
        },
        {
          displayName: "Integrações",
          disabled: true,
          iconName: "apartment",
          iconType: "nz-icon",
          route: "/integracoes/",
          visible: hasSomeRole(getPermissoes("/integracoes")),
        },
        {
          displayName: "Integrações",
          disabled: true,
          iconName: "apartment",
          iconType: "nz-icon",
          route: "/integracoes-new",
          visible: hasSomeRole(getPermissoes("/integracoes-new")),
          badge: "new!",
        },
        {
          displayName: "Pare",
          disabled: true,
          iconName: "stop",
          iconType: "nz-icon",
          route: "/pare/",
          visible: hasSomeRole(getPermissoes("/pare")),
        },
        {
          displayName: "Auditoria",
          disabled: true,
          iconName: "audit",
          iconType: "nz-icon",
          route: "/auditoria/",
          visible: hasSomeRole(getPermissoes("/auditoria")),
          badge: "new!",
        },
      ],
    });
  }

  const menuGerado = menu
    .map((menuGroup) => ({
      ...menuGroup,
      itens: menuGroup.itens.filter((option) => option.visible),
    }))
    .filter((menuGroup) => menuGroup.itens.length > 0);

  return menuGerado;
}
